import React, { Fragment, ReactElement } from 'react';
import classNames from 'classnames';
import styles from './styles.module.css';

import { ArrowButton } from 'src/components/ArrowButton';
import { TextInput } from 'src/components/TextInput';

import OneDie from './PNGs/dice-1.png';
import TwoDie from './PNGs/dice-2.png';
import ThreeDie from './PNGs/dice-3.png';
import FourDie from './PNGs/dice-4.png';
import FiveDie from './PNGs/dice-5.png';
import SixDie from './PNGs/dice-6.png';
import { Tooltip } from 'src/components/Tooltip';

interface Props {
	dice1: number;
	dice2: number;
	dice3: number;
	roll: number;
	result?: number;

	isDisabled?: boolean;
	nextQuestion?: (arg0?: string | undefined) => void;
}

interface State {
	currAnswer: string;
}

export class Roll extends React.Component<Props, State> {
	state: State = {
		currAnswer: '',
	};

	imageMap: { [key: number]: string } = {
		1: OneDie,
		2: TwoDie,
		3: ThreeDie,
		4: FourDie,
		5: FiveDie,
		6: SixDie,
	};

	answerQuestion = () => {
		if (this.state.currAnswer && this.props.nextQuestion) {
			this.props.nextQuestion(this.state.currAnswer.toString());
		}
		this.setState({ currAnswer: '' });
	};

	render() {
		const Die1Src = this.imageMap[this.props.dice1];
		const Die2Src = this.imageMap[this.props.dice2];
		const Die3Src = this.imageMap[this.props.dice3];
		const skipTooltip = `Submits this question with no answer. The question cannot be attempted later.`;
		return (
			<div className={styles.container}>
				<div className={styles.dice}>
					<div className={classNames(styles.die, styles.rotateClockWise)}>
						<img src={Die1Src} alt={`${this.props.dice1} Die`} />
					</div>
					<div className={classNames(styles.die, styles.rotateCounterClockWise)}>
						<img src={Die2Src} alt={`${this.props.dice2} Die`} />
					</div>
					<div className={classNames(styles.die, styles.rotateClockWise)}>
						<img src={Die3Src} alt={`${this.props.dice3} Die`} />
					</div>
				</div>

				{this.props.result !== undefined &&
				this.props.result !== NaN &&
				this.props.result !== null ? (
					<div className={styles.result}>
						<div className={styles.resultNum}>{this.props.result}</div>
						<div className={styles.resultText}>Example Roll {this.props.roll + 1} </div>
					</div>
				) : (
					<div className={styles.answerContainer}>
						<TextInput
							caption={
								'Figure out how the above roll results were calculated to give the result of this roll:'
							}
							additionalStyles={styles}
							placeholder={'Your answer...'}
							onValueChanged={(answerValue) => {
								this.setState({ currAnswer: answerValue });
							}}
							onEnter={this.answerQuestion}
							value={this.state.currAnswer}
						/>
						<div className={styles.submitContainer}>
							<ArrowButton
								caption="Submit Answer"
								onClick={this.answerQuestion}
								isDisabled={this.state.currAnswer === ''}
							/>
							<Tooltip caption={skipTooltip}>
								<button
									className={styles.skipButton}
									onClick={() => {
										if (this.props.nextQuestion) {
											this.setState({ currAnswer: '' });
											this.props.nextQuestion();
										}
									}}
								>
									{`Skip >`}
								</button>
							</Tooltip>
						</div>
					</div>
				)}
			</div>
		);
	}
}
