import React from 'react';

import { Roll } from './components/Roll';

export interface DiceGameQuestionSpec {
	exampleRolls: { dice: number[]; result: number }[];
	questionRoll: { dice: number[] };
}

interface Props {
	currQuestionNum: number;
	currQuestionSpec: DiceGameQuestionSpec;
	questionTotal: number;
	nextQuestion: (answer: string | undefined) => void;
}

export class Questions extends React.Component<Props> {
	render() {
		return (
			<>
				{this.props.currQuestionSpec.exampleRolls.map((roll, i) => {
					return (
						<Roll
							dice1={roll.dice[0]}
							dice2={roll.dice[1]}
							dice3={roll.dice[2]}
							key={i}
							roll={i}
							result={roll.result}
						/>
					);
				})}
				{/* Answer row*/}
				<Roll
					dice1={this.props.currQuestionSpec.questionRoll.dice[0]}
					dice2={this.props.currQuestionSpec.questionRoll.dice[1]}
					dice3={this.props.currQuestionSpec.questionRoll.dice[2]}
					roll={4}
					nextQuestion={this.props.nextQuestion}
					isDisabled={false}
				/>
			</>
		);
	}
}
